import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useHttpClient } from 'hooks/http-hook';
import { AuthContext } from 'context/auth-context';
import Input from 'components/FormElements/Input/Input';
import ErrorModal from 'components/ErrorModal/ErrorModal';
import Button from 'components/Button/Button';
import LoadingAnimation from 'components/LoadingAnimation/LoadingAnimation';
import R2Icon from 'components/R2Icon/R2Icon';
import './Auth.scss';

const Auth = () => {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const [isLoginMode, setIsLoginMode] = useState(true);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailIsValid, setEmailIsValid] = useState(true);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const switchModeHandler = () => {
    setIsLoginMode(!isLoginMode);
  };

  const submitHandler = async event => {
    event.preventDefault();
    if (isLoginMode) {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + '/users/login',
          'POST',
          JSON.stringify({
            email,
            password,
          }),
          {
            'Content-Type': 'application/json'
          }
        );
        auth.login(responseData.userId, responseData.token);
        navigate('/');
      } catch (err) { }
    } else {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + '/users/signup',
          'POST',
          JSON.stringify({
            name,
            email,
            password,
          }),
          {
            'Content-Type': 'application/json'
          }
        );
        auth.login(responseData.userId, responseData.token);
        navigate('/');
      } catch (err) { }
    }
  };

  const checkValidators = (inputValue) => {
    // curently only checking to see if it's empty
    if (inputValue.length === 0) {
      setEmailIsValid(false);
    } else {
      setEmailIsValid(true);
    }
  }

  return (
    <>
      <ErrorModal error={error} onClear={clearError} />
      <div className="authentication">
        {isLoading && <LoadingAnimation asOverlay />}

        <div className="authentication__logo">
          <R2Icon />
        </div>

        <form className='authentication__form' onSubmit={submitHandler}>

          {!isLoginMode && (
            <Input
              inputClassName='authentication__name-input'
              autoFocus={true}
              id="name"
              type="name"
              placeholder='Name'
              errorText="Please enter a name."
              onChange={e => setName(e.target.value)}
            />
          )}

          <Input
            inputClassName='authentication__email-input'
            autoFocus={true}
            id="email"
            isValid={emailIsValid}
            type="email"
            placeholder='Email'
            errorText="Please enter a valid email address."
            onBlur={e => checkValidators(e.target.value)}
            onChange={e => setEmail(e.target.value)}
          />

          <Input
            inputClassName='authentication__password-input'
            id="password"
            type="password"
            placeholder='Password'
            errorText="Please enter a valid password, at least 6 characters."
            onChange={e => setPassword(e.target.value)}
          />

          {isLoginMode ?
            <div className='authentication__button-container'>
              <Button
                className='authentication__button'
                element='button'
                text='Request sign up'
                type='button'
                variant='text'
                onClick={switchModeHandler}
              />
              <Button
                className='authentication__button'
                element='button'
                text='Sign in'
                type='submit'
              />
            </div>

            :

            <div className='authentication__button-container'>
              <Button
                className='authentication__button'
                element='button'
                text='Switch to Sign in'
                type='button'
                variant='text'
                onClick={switchModeHandler}
              />
              <Button
                className='authentication__button'
                element='button'
                type='submit'
                text='Sign up'
              />
            </div>
          }
        </form>

      </div>
    </>
  );
};

export default Auth;