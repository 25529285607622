import { useContext, useState, useEffect, useCallback } from 'react';
import { NavLink, useLocation } from "react-router-dom";
import { AuthContext } from 'context/auth-context';
import { useNavigate } from "react-router-dom";
import { useHttpClient } from 'hooks/http-hook';
import { useCurrentDate } from 'hooks/date-hook';
import moment from 'moment';
import classes from "./MainNav.scss";
import Version from 'components/Version/Version';
import "./MainNav.scss";

/**
 * Renders the main nav including Sign In/Out and More/Less links. Does not include SearchAndCreateBar.
 * 
 * Props: jesseAccountPublicViewMode, onCloseMainNav, userCreatedPages
*/
const MainNav = (props) => {
	const auth = useContext(AuthContext);
	const location = useLocation();
	const currentDate = useCurrentDate();
	const currentDateString = moment(currentDate).format('YYYY-MM-DD').toString();
	const [todayLinkIsActive, setTodayLinkIsActive] = useState(false);
	const [yesterdayLinkIsActive, setYesterdayLinkIsActive] = useState(false);
	const { sendRequest } = useHttpClient();
	const navigate = useNavigate();
	const userCreatedPagesAtoZ = props.userCreatedPages.sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()));
	const [lastFiveRecordedDays, setLastFiveRecordedDays] = useState();
	const yesterdayDateString = moment(currentDate).subtract(1, 'days').format('YYYY-MM-DD');

	useEffect(() => {
		if (location.pathname.includes(currentDateString)) {
			setTodayLinkIsActive(true);
		} else {
			setTodayLinkIsActive(false);
		}

		if (location.pathname.includes(yesterdayDateString)) {
			setYesterdayLinkIsActive(true);
		} else {
			setYesterdayLinkIsActive(false);
		}
	}, [location, currentDateString, yesterdayDateString]);

	// Fetch Days function
	const fetchLastFiveRecordedDays = useCallback(async () => {
		try {
			const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/days/${auth.userId}/days/${currentDateString}/5`);
			return responseData.days;
		} catch (err) {
			console.error('error', err);
		}
	}, [sendRequest, auth.userId, currentDateString]);

	// Get last five recorded Days for Today/Yesterday links
	useEffect(() => {
		if (auth.userId ) {
			fetchLastFiveRecordedDays().then(fetchedDays => {
				setLastFiveRecordedDays(fetchedDays);
			});
		}
	}, [auth.userId, currentDateString, fetchLastFiveRecordedDays]);

	const closeSubnavs = () => {
		const subnav = document.querySelector('.mainnav__subnav--active');
		subnav?.classList.remove('mainnav__subnav--active');
	}

	const closeMainNavHandler = () => {
		closeSubnavs();
		props.onCloseMainNav();
	}

	const toggleSubnavHandler = (e) => {
		const mainnav = document.querySelector('.mainnav');
		mainnav.classList.remove('mainnav--hide-all-subnavs');

		const targetSubnav = e.target.nextSibling;

		if (targetSubnav.classList.contains('mainnav__subnav--active')) {
			targetSubnav.classList.remove('mainnav__subnav--active');
		} else {
			closeSubnavs();
			targetSubnav.classList.add('mainnav__subnav--active');
		}
	}

	const goToToday = () => {
		if (lastFiveRecordedDays.some(x => x.dateString === currentDateString)) {
			navigate(`/days/edit/${currentDateString}`);
		} else {
			navigate(`/days/create`);
		}
		closeMainNavHandler();
	}

	const goToYesterday = () => {
		if (lastFiveRecordedDays.some(x => x.dateString === yesterdayDateString)) {
			navigate(`/days/edit/${yesterdayDateString}`);
		} else {
			navigate(`/days/create`);
		}
		closeMainNavHandler();
	}

	return (
		<>
			<div className='mainnav'>
				<Version />

				<div className='mainnav__slides-container'>
					<div className='mainnav__slide'>
						<div className="mainnav__mainlink">
							<NavLink
								to="/"
								onClick={closeMainNavHandler}
							>
								Home
							</NavLink>
						</div>

						{props.jesseAccountPublicViewMode === 'private' &&
							<div className="mainnav__mainlink">
								<NavLink activeclassname={classes.active}
									to="/dashboard"
									onClick={closeMainNavHandler}
								>
									Dashboard
								</NavLink>
							</div>
						}

						{props.jesseAccountPublicViewMode === 'private' &&
							<>
								<div className="mainnav__mainlink">
									<NavLink activeclassname={classes.active}
										to={`/${auth.userId}/days`}
										onClick={closeMainNavHandler}
									>
										Days
									</NavLink>
								</div>
								<div className="mainnav__mainlink">
									<div className={todayLinkIsActive ? 'active' : ''}
										onClick={() => { goToToday() }}
									>
										Today
									</div>
								</div>
								<div className="mainnav__mainlink">
									<div className={yesterdayLinkIsActive ? 'active' : ''}
										onClick={() => { goToYesterday() }}
									>
										Yesterday
									</div>
								</div>
							</>
						}

						<div className="mainnav__mainlink">
							<NavLink activeclassname={classes.active}
								to={`/${auth.userId}/tasks`}
								onClick={closeMainNavHandler}
							>
								Tasks
							</NavLink>
						</div>

						<div className="mainnav__mainlink" onClick={toggleSubnavHandler}>
							Flash Cards
						</div>
						<div className="mainnav__subnav">
							<div className='mainnav__subnav-links-container'>
								<div className="mainnav__subnav-link">
									<NavLink activeclassname={classes.active}
										to={`/${auth.userId}/flash-cards-game`}
										onClick={closeMainNavHandler}
									>
										Play
									</NavLink>
								</div>
								<div className="mainnav__subnav-link">
									<NavLink activeclassname={classes.active}
										to={`/${auth.userId}/flash-cards`}
										onClick={closeMainNavHandler}
									>
										View All
									</NavLink>
								</div>
							</div>
						</div>

						<div className="mainnav__mainlink">
							<NavLink
								to="/coin-flip"
								onClick={closeMainNavHandler}
							>
								Coin
							</NavLink>
						</div>
						
					</div>
					<div className='mainnav__slide'>
						{auth.userId === `${process.env.REACT_APP_JESSE_ID}` && (
							<>
								<div className="mainnav__mainlink" onClick={toggleSubnavHandler}>
									R2
								</div>
								<div className="mainnav__subnav">
									<div className='mainnav__subnav-links-container'>
										{userCreatedPagesAtoZ?.map(page => (
											page.pageParent.toLowerCase() === 'r2' && props.jesseAccountPublicViewMode === 'private' ?
												<div className="mainnav__subnav-link" key={page.id}>
													<NavLink activeclassname={classes.active}
														to={`${page.pageParent.toLowerCase()}/${page.id}`}
														onClick={closeMainNavHandler}
													>
														{page.title}
													</NavLink>
												</div>
												:
												page.pageParent.toLowerCase() === 'r2' && props.jesseAccountPublicViewMode === 'public' && page.public === true ?
													<div className="mainnav__subnav-link" key={page.id}>
														<NavLink activeclassname={classes.active}
															to={`${page.pageParent.toLowerCase()}/${page.id}`}
															onClick={closeMainNavHandler}
														>
															{page.title}
														</NavLink>
													</div>
													:
													null
										))}
									</div>
								</div>

								<div className="mainnav__mainlink" onClick={toggleSubnavHandler}>
									Sites
								</div>
								<div className="mainnav__subnav">
									<div className='mainnav__subnav-links-container'>
										<div className="mainnav__subnav-link">
											<NavLink
												to={`http://hangman.jessewhite.site/`}
												target={'_blank'}
												onClick={closeMainNavHandler}
											>
												hangman
											</NavLink>
										</div>
										<div className="mainnav__subnav-link">
											<NavLink
												to={`http://archives.jessewhite.site/index.php`}
												target={'_blank'}
												onClick={closeMainNavHandler}
											>
												archives
											</NavLink>
										</div>
									</div>
								</div>

								<div className="mainnav__mainlink" onClick={toggleSubnavHandler}>
									Auto
								</div>
								<div className="mainnav__subnav">
									<div className='mainnav__subnav-links-container'>
										{userCreatedPagesAtoZ?.map(page => (
											page.pageParent.toLowerCase() === 'auto' && props.jesseAccountPublicViewMode === 'private' ?
												<div className="mainnav__subnav-link" key={page.id}>
													<NavLink activeclassname={classes.active}
														to={`${page.pageParent.toLowerCase()}/${page.id}`}
														onClick={closeMainNavHandler}
													>
														{page.title}
													</NavLink>
												</div>
												:
												page.pageParent.toLowerCase() === 'auto' && props.jesseAccountPublicViewMode === 'public' && page.public === true ?
													<div className="mainnav__subnav-link" key={page.id}>
														<NavLink activeclassname={classes.active}
															to={`${page.pageParent.toLowerCase()}/${page.id}`}
															onClick={closeMainNavHandler}
														>
															{page.title}
														</NavLink>
													</div>
													:
													null
										))}
									</div>
								</div>

								{props.jesseAccountPublicViewMode === 'private' &&
									<>
										<div className="mainnav__mainlink" onClick={toggleSubnavHandler}>
											Family
										</div>
										<div className="mainnav__subnav">
											<div className='mainnav__subnav-links-container'>
												{userCreatedPagesAtoZ?.map(page => (
													page.pageParent.toLowerCase() === 'family' && props.jesseAccountPublicViewMode === 'private' ?
														<div className="mainnav__subnav-link" key={page.id}>
															<NavLink activeclassname={classes.active}
																to={`${page.pageParent.toLowerCase()}/${page.id}`}
																onClick={closeMainNavHandler}
															>
																{page.title}
															</NavLink>
														</div>
														:
														page.pageParent.toLowerCase() === 'family' && props.jesseAccountPublicViewMode === 'public' && page.public === true ?
															<div className="mainnav__subnav-link" key={page.id}>
																<NavLink activeclassname={classes.active}
																	to={`${page.pageParent.toLowerCase()}/${page.id}`}
																	onClick={closeMainNavHandler}
																>
																	{page.title}
																</NavLink>
															</div>
															:
															null
												))}
											</div>
										</div>
									</>
								}

								{/* <div className="mainnav__mainlink">
									<NavLink activeclassname={classes.active}
										to="/users"
										onClick={closeMainNavHandler}
									>
										Users
									</NavLink>
								</div> */}
							</>
						)}

						<div className="mainnav__mainlink">
							<NavLink activeclassname={classes.active}
								to={`/${auth.userId}/notes`}
								onClick={closeMainNavHandler}
							>
								Notes
							</NavLink>
						</div>

						<div className="mainnav__mainlink">
							<NavLink activeclassname={classes.active}
								to={`/${auth.userId}/recipes`}
								onClick={closeMainNavHandler}
							>
								Recipes
							</NavLink>
						</div>

						<div className="mainnav__mainlink" onClick={toggleSubnavHandler}>
							Music
						</div>
						<div className="mainnav__subnav">
							<div className='mainnav__subnav-links-container'>
								<NavLink activeclassname={classes.active}
									to={`/${auth.userId}/albums`}
									onClick={closeMainNavHandler}
								>
									Albums
								</NavLink>
							</div>
						</div>

						{props.jesseAccountPublicViewMode === 'private' &&
							<div className="mainnav__mainlink">
								<NavLink activeclassname={classes.active}
									to={`/${auth.userId}/phrases`}
									onClick={closeMainNavHandler}
								>
									Phrases
								</NavLink>
							</div>
						}

						{props.jesseAccountPublicViewMode === 'private' &&
							<div className="mainnav__mainlink">
								<NavLink activeclassname={classes.active}
									to={`/${auth.userId}/maxims`}
									onClick={closeMainNavHandler}
								>
									Maxims
								</NavLink>
							</div>
						}

						<div className="mainnav__mainlink">
							<NavLink activeclassname={classes.active}
								to={`/${auth.userId}/wallpapers`}
								onClick={closeMainNavHandler}
							>
								Wallpapers
							</NavLink>
						</div>
					</div>
				</div>
			</div>

			<div className='mainnav__bottom-links'>
				{!auth.isLoggedIn && (
					<div className="mainnav__mainlink mainnav__mainlink--auth">
						<NavLink to="/auth" onClick={closeMainNavHandler}>
							Sign In
						</NavLink>
					</div>
				)}
				{auth.isLoggedIn && (
					<div className="mainnav__mainlink mainnav__mainlink--auth">
						<NavLink onClick={() => { auth.logout(); closeMainNavHandler() }} to="auth">
							Sign Out
						</NavLink>
					</div>
				)}
			</div>
		</>
	);
};

export default MainNav;