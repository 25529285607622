import { useState, useEffect } from 'react';
import Tooltip from 'components/Tooltip/Tooltip';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import './Input.scss';

/**
 * Renders an input field and optional label wrapped in a form-group div.
 * 
 * Props: autoFocus, copyFrom, inputClassName, formGroupClassName, errorText, defaultValue, disabled, id, isValid, label, name, onChange, onBlur, placeholder, required, tooltip, tooltipIcon, tooltipContent, type
 */
const Input = (props) => {
  const [incrementDisabled, setIncrementDisabled] = useState(false);
  const [decrementDisabled, setDecrementDisabled] = useState(false);
  const step = isNaN(props.step) ? 1 : props.step ?? 1;

  useEffect(() => {
    const val = parseFloat(props.value) || 0;
    setIncrementDisabled(props.max !== undefined && val >= props.max);
    setDecrementDisabled(props.min !== undefined && val <= props.min);
  }, [props.value, props.min, props.max]);

  const scale = 10 ** (step.toString().split('.')[1]?.length || 0);

  const roundToStep = (num) => {
    return Math.round(num * scale) / scale;
  };

  let inputClassName = `input`;
  if (props.inputClassName) {
    inputClassName += ` ${props.inputClassName}`;
  }
  if (props.type === 'number') {
    inputClassName += ` input--number`;
  }

  let formGroupClassName = `form-group`;
  if (props.formGroupClassName) {
    formGroupClassName += ` ${props.formGroupClassName}`;
  }
  if (props.isValid === false) {
    formGroupClassName += ` form-group--invalid`;
  }
  if (props.tooltip) {
    formGroupClassName += ` form-group--with-tooltip`;
  }

  let incrementButtonClassName = 'input--number__increment-button';
  if (incrementDisabled) {
    incrementButtonClassName += ' input--number__increment-button--disabled';
  }

  let decrementButtonClassName = 'input--number__decrement-button';
  if (decrementDisabled) {
    decrementButtonClassName += ' input--number__decrement-button--disabled';
  }

  const increment = () => {
    const val = parseFloat(props.value) || 0;
    const newValue = Number(roundToStep((val * scale + (step * scale)) / scale));
    if (props.value === null || props.value === undefined) {
      props.onChange({ target: { value: 0 } });
    } else if (props.max !== undefined && newValue >= props.max) {
      props.onChange({ target: { value: props.max } });
    } else {
      props.onChange({ target: { value: newValue } });
    }
  };

  const decrement = () => {
    const val = parseFloat(props.value) || 0;
    const newValue = roundToStep((val * scale - (step * scale)) / scale);
    if (props.min !== undefined && newValue <= props.min) {
      props.onChange({ target: { value: props.min } });
    } else {
      props.onChange({ target: { value: newValue } });
    }
  };

  return (
    <div className={formGroupClassName}>
      <div className='input-container'>
        {props.label && <label className='label input__label' htmlFor={props.id}>{props.label}</label>}
        {props.copyFrom && (
          <span
            onClick={() => props.onCopyFromClick(props.copyFrom)}
            className="label input__copy-from"
          >
            {props.copyFrom}
          </span>
        )}
        {props.type === 'number' && 
          <button
            tabIndex={-1}
            className={decrementButtonClassName}
            onClick={() => !decrementDisabled && decrement()}
            type='button'
          >
            <RemoveIcon />
          </button>
        }
        <input
          autoFocus={props.autoFocus}
          className={inputClassName}
          defaultValue={props.defaultValue}
          disabled={props.disabled}
          id={props.id}
          name={props.name}
          onChange={props.onChange}
          onKeyDown={props.onKeyDown}
          onBlur={props.onBlur}
          placeholder={props.placeholder}
          required={props.required}
          type={props.type}
          value={props.value}
          min={props.min}
          max={props.max}
          step={props.step}
          autoComplete={props.autocomplete ? 'off' : 'on'} // prevent browser from giving suggestions on autocomplete components
        />
        {props.type === 'number' &&
          <button
            tabIndex={-1}
            className={incrementButtonClassName}
            onClick={() => !incrementDisabled && increment()}
            type='button'
          >
            <AddIcon />
          </button>
        }
        {props.errorText && <span className='error-text input__error-text'>{props.errorText}</span>}
      </div>
      {props.tooltip &&
        <Tooltip
          className={props.label ? 'tooltip--in-form-group-with-label' : ''}
          icon={props.tooltipIcon}
          content={props.tooltipContent}
        />
      }
    </div>
  )
};

export default Input;