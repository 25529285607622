import React, { useEffect, useState } from 'react';
import './CoinFlip.scss';

const CoinFlip = () => {
  const [loading, setLoading] = useState(false);
  const [headsUrl, setHeadsUrl] = useState(null);
  const [tailsUrl, setTailsUrl] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
    const fetchImageUrl = async () => {
      const backend = process.env.REACT_APP_BACKEND_URL?.replace('api', '');
      if (!backend) {
        setError('Backend URL not set');
        return;
      }

      try {
        const [headsRes, tailsRes] = await Promise.all([
          fetch(`${backend}get-image-url?fileName=coin-heads.png`),
          fetch(`${backend}get-image-url?fileName=coin-tails.png`)
        ]);

        if (!headsRes.ok) throw new Error('Failed to fetch heads image');
        if (!tailsRes.ok) throw new Error('Failed to fetch tails image');

        const headsData = await headsRes.json();
        const tailsData = await tailsRes.json();

        setHeadsUrl(headsData.url);
        setTailsUrl(tailsData.url);
      } catch (err) {
        console.error('Error fetching image URL:', err);
        setError('Could not load images');
      } finally {
        setTimeout(() => {
          setLoading(false);
        }, 500);
      }      
    };

    fetchImageUrl();
  }, []);

  const flipCoin = () => {
    const coin = document.getElementById("coin");
    if (!coin) return;

    coin.classList.remove("heads", "tails");

    setTimeout(() => {
      coin.classList.add(Math.random() <= 0.5 ? "heads" : "tails");
    }, 100);
  };

  return (
    <div className="coin-flip">
      <div className="coin-flip__shadow"></div>
      {error && <p className="error">{error}</p>}
      <div id="coin" onClick={flipCoin}>
        {(loading || !headsUrl || !tailsUrl)
          ? <div className='coin-flip__loading'>?</div>
          : (
            <>
              <div className="side-a" style={{ backgroundImage: `url("${headsUrl}")` }}></div>
              <div className="side-b" style={{ backgroundImage: `url("${tailsUrl}")` }}></div>
            </>
          )
        }
      </div>
    </div>
  );
};

export default CoinFlip;
